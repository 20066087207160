import { postReviewApiDetails } from '../../constants/server.constants';
import gateway from '../../middlewares/axios/gateway';

const reviewRestaurantService = async (restaurantId, rating, description) => {
  const response = await gateway.post(postReviewApiDetails.path, {
    restaurantId, rating, description,
  });

  return response.data;
};

export default reviewRestaurantService;
