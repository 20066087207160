import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Skeleton, Tab, Tabs, Typography,
} from '@mui/material';
import {
  node, number, shape, string,
} from 'prop-types';
import { Stack } from '@mui/system';
import { HomePath, PublicProfileUsernameParam } from '../../constants/routes.contants';
import getPublicProfileService from '../../services/profile/public';
import { devlog } from '../../utils/console';
import RestaurantSkeletonComponent from '../restaurant/restaurantSkeleton';
import ColumnLayout from '../../layouts/column';
import ProfileHeroComponent from '../../components/profileHero/profileHero';
import SectionHeader from '../../components/typography/sectionHeader';
import ReviewCardComponent from '../../components/review/reviewCard';
import getRestaurantReviewsService from '../../services/review/getRestaurantReviews';
import ComingSoonComponent from '../../components/misc/comingSoon';
import TagComponent from '../../components/tags/tag';

function ReviewsTabPanel({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);

      const reviewRes = await getRestaurantReviewsService(0, userId, 5, 0);
      const reviewData = reviewRes?.data;
      if (reviewData) {
        const { profileInfos, restaurantInfos } = reviewData;
        const formattedReview = reviewData.reviews.map((val) => ({
          id: val.id,
          username: profileInfos[val.userId]?.username,
          description: val.description,
          rating: val.rating,
          restaurantName: restaurantInfos[val.restaurantId].name,
          restaurantId: restaurantInfos[val.restaurantId].id,
        }));

        devlog(formattedReview);
        setReviews(formattedReview);
      }

      setIsLoading(false);
    };

    helper();
  }, [userId]);

  return (
    <>
      <SectionHeader text="reviews." textAlign="left" />
      <ColumnLayout overflow="flex">
        {isLoading && [0, 1, 2, 3, 4].map((val) => (
          <Skeleton
            key={val}
            variant="rounded"
            width="100%"
            height="222px"
          />
        ))}
      </ColumnLayout>
      {reviews?.length !== 0 && (
        <ColumnLayout overflow="flex">
          {reviews?.map((val) => (
            <ReviewCardComponent
              key={val.id}
              name={val.username}
              reviewText={val.description}
              rating={val.rating}
              restaurantId={val.restaurantId}
              restaurantName={val.restaurantName}
            />
          ))}
        </ColumnLayout>
      )}
    </>
  );
}

ReviewsTabPanel.propTypes = {
  userId: number.isRequired,
};

function DetailsTabPanel({ profileDetails }) {
  const { tags } = profileDetails;

  return (
    <ColumnLayout overflow="flex" justifyContent="flex-start">
      <SectionHeader text="bio." textAlign="left" />
      {profileDetails?.bio?.length
        && profileDetails.bio.split('\n').map((val) => (
          <Typography textAlign="left" key={val}>
            {val}
          </Typography>
        ))}
      <SectionHeader text="preferences." textAlign="left" />
      <Typography variant="h6" fontWeight="bold" textAlign="left">tags.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
          && tags.filter((val) => val.type === 1)
            .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
      <Typography variant="h6" fontWeight="bold" textAlign="left">meal type.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
          && tags.filter((val) => val.type === 3)
            .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
      <Typography variant="h6" fontWeight="bold" textAlign="left">location.</Typography>
      <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
        {tags.length !== 0
          && tags.filter((val) => val.type === 4)
            .map((val) => <TagComponent key={val.id} label={val.name} />)}
      </Stack>
    </ColumnLayout>
  );
}

DetailsTabPanel.propTypes = {
  profileDetails: shape({ bio: string }).isRequired,
};

function CustomTabPanel(props) {
  const {
    children, value, index,
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: number.isRequired,
};

function TabSection({ profileDetails }) {
  // const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const onClickRecommend = () => {
  //   navigate(RecommendPath, { state: { userId: profileDetails.id, list: 'done' } });
  // };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="details" />
          <Tab label="reviews" />
          <Tab label="recommendations" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DetailsTabPanel profileDetails={profileDetails} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReviewsTabPanel userId={profileDetails.id} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ComingSoonComponent />
        {/* <Button variant="contained" onClick={onClickRecommend}>recommend me</Button> */}
      </CustomTabPanel>
    </Box>
  );
}

TabSection.propTypes = {
  profileDetails: shape({
    id: number,
    bio: string,
  }).isRequired,
};

function PublicProfilePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});

  const params = useParams();
  const username = params[PublicProfileUsernameParam];

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      const { data } = await getPublicProfileService(username);

      devlog(data);

      const profileInfo = data?.profileInfo;

      if (!profileInfo) {
        navigate(HomePath);
      } else {
        setProfileDetails(profileInfo);
      }

      setIsLoading(false);
    };

    helper();
  }, [username]);

  if (isLoading || Object.keys(profileDetails).length === 0) {
    return <RestaurantSkeletonComponent />;
  }

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="100%" minWidth="350px" maxWidth="550px">
        <ColumnLayout overflow="flex" alignItems="center">
          <ProfileHeroComponent
            username={profileDetails?.username}
            avatarImage={profileDetails?.profileImage}
            joinDate={profileDetails?.createdAt}
            country={profileDetails?.country}
          />
          <TabSection profileDetails={profileDetails} />
        </ColumnLayout>
      </Box>
    </Box>
  );
}

export default PublicProfilePage;
