import React, { useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, Stack, Typography,
} from '@mui/material';
import { node } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ProfileHeroComponent from '../../components/profileHero/profileHero';
import ColumnLayout from '../../layouts/column';
import SectionHeader from '../../components/typography/sectionHeader';
import RestaurantCardComponent from '../../components/restaurantCard/restaurantCard';
import getProfileInfoService from '../../services/profile/details';
import { devlog } from '../../utils/console';
import getRestaurantReviewsService from '../../services/review/getRestaurantReviews';
import { GetUserId } from '../../utils/localStorage';
import ReviewCardComponent from '../../components/review/reviewCard';
import TagComponent from '../../components/tags/tag';
import { CompleteProfilePath, PublicProfilePathBuilder, UpdateProfilePath } from '../../constants/routes.contants';
import RowLayout from '../../layouts/rows';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import { getFeBaseUrl } from '../../utils/env';

// 17 char max usernames

function ProfileSection({ children }) {
  return (
    <Box
      border={1}
      borderRadius="5px"
      minHeight="100px"
      flexDirection="column"
      width="100%"
      sx={{
        MozBoxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        'box-sizing': 'border-box',
      }}
      display="flex"
      p={1}
    >
      {children}
    </Box>
  );
}

// const demoProfile = {
//   bio: 'playmade taiwan milk tea 25% less ice.\nmala zhong la',
//   name: '',
//   image: '',
//   username: 'bignoodles',
//   country: 'singapore',
//   ctime: 877795200,
//   price: '$',
//   spicy: 'no thanks',
//   queueing: 'first time only',
//   preferenceText: 'price: $\nspicy: no thanks\nqueueing: first time only',
//   recommended: [{
//     restaurantId: 'fewgegwe',
//     restaurantName: 'Tai One Wei',
//     restaurantAddress: '111 East Coast Road, Singapore 123456',
//     isSaved: true,
//     isOpen: true,
//     country: 'singapore',
//     tags: ['taiwanese', 'chicken'],
//     images: [],
//     hasReviewed: false,
//   }],
// };

ProfileSection.propTypes = {
  children: node.isRequired,
};

function ProfilePage() {
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [recommended, setRecommended] = useState();
  const userId = GetUserId();
  const [reviews, setReviews] = useState([]);

  const { notify } = useCustomSnackbar();

  const navigate = useNavigate();

  const onNotComplete = () => {
    navigate(CompleteProfilePath);
  };

  useEffect(() => {
    const helper = async () => {
      setIsLoading(true);
      try {
        const { data } = await getProfileInfoService();

        if (!data.completedProfile) {
          onNotComplete();
          return;
        }

        setProfile(data?.profileInfo);
        setRecommended(data?.recommend);

        const reviewRes = await getRestaurantReviewsService(0, userId, 5, 0);
        const reviewData = reviewRes?.data;
        if (reviewData) {
          const { profileInfos, restaurantInfos } = reviewData;
          const formattedReview = reviewData.reviews.map((val) => ({
            id: val.id,
            username: profileInfos[val.userId]?.username,
            description: val.description,
            rating: val.rating,
            restaurantName: restaurantInfos[val.restaurantId].name,
            restaurantId: restaurantInfos[val.restaurantId].id,
          }));

          devlog(formattedReview);
          setReviews(formattedReview);
        }
      } catch (err) {
        devlog(err);
      }
      setIsLoading(false);
    };
    helper();
  }, []);

  const onClickShare = async () => {
    const url = `${getFeBaseUrl()}${PublicProfilePathBuilder(profile.username)}`;
    if (!navigator.share) {
      navigator.clipboard.writeText(url);
      notify('copied share link to clipboard');
      return;
    }

    const shareData = {
      title: 'crayoneats profile',
      text: 'come see my profile!',
      url,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      devlog(err);
    }
  };

  return (
    <Box justifyContent="center" display="flex">
      <Box mx="16px" width="100%" minWidth="350px" maxWidth="550px">
        {isLoading ? <CircularProgress /> : (
          <ColumnLayout overflow="flex" alignItems="center">
            <ProfileHeroComponent
              username={profile?.username}
              avatarImage={profile?.profileImage}
              joinDate={profile?.createdAt}
              country={profile?.country}
            />
            <Box width="100%">
              <RowLayout>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(UpdateProfilePath, {
                      state: {
                        name: profile.name,
                        bio: profile.bio,
                        country: profile.country,
                        tags: profile.tags,
                        profileImage: profile.profileImage,
                        profileImageKey: profile.profileImageKey,
                      },
                    });
                  }}
                  sx={{ width: '50%' }}
                >
                  edit profile
                </Button>
                <Button
                  variant="outlined"
                  sx={{ width: '50%' }}
                  onClick={onClickShare}
                >
                  share
                </Button>
              </RowLayout>
            </Box>

            <ProfileSection>
              <SectionHeader text="bio." textAlign="left" />
              {profile?.bio?.length
                && profile.bio.split('\n').map((val) => <Typography textAlign="left" key={val}>{val}</Typography>)}
              <SectionHeader text="preferences." textAlign="left" />
              <Typography variant="h6" fontWeight="bold" textAlign="left">tags.</Typography>
              <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
                {profile?.tags?.length !== 0
                  && profile?.tags?.filter((val) => val.type === 1)
                    .map((val) => <TagComponent key={val.id} label={val.name} />)}
              </Stack>
              <Typography variant="h6" fontWeight="bold" textAlign="left">meal type.</Typography>
              <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
                {profile?.tags?.length !== 0
                  && profile?.tags?.filter((val) => val.type === 3)
                    .map((val) => <TagComponent key={val.id} label={val.name} />)}
              </Stack>
              <Typography variant="h6" fontWeight="bold" textAlign="left">location.</Typography>
              <Stack direction="row" spacing="10px" useFlexGap flexWrap="wrap">
                {profile?.tags?.length !== 0
                  && profile?.tags?.filter((val) => val.type === 4)
                    .map((val) => <TagComponent key={val.id} label={val.name} />)}
              </Stack>
            </ProfileSection>
            <ProfileSection>
              <SectionHeader text="reviews." textAlign="left" />
              {reviews?.length !== 0 && (
                <ColumnLayout overflow="flex">
                  {reviews?.map((val) => (
                    <ReviewCardComponent
                      key={val.id}
                      name={val.username}
                      reviewText={val.description}
                      rating={val.rating}
                      restaurantId={val.restaurantId}
                      restaurantName={val.restaurantName}
                    />
                  ))}
                </ColumnLayout>
              )}
            </ProfileSection>

            <ProfileSection>
              <SectionHeader text={`${profile?.username} recos`} textAlign="left" />
              {recommended?.length !== 0 && recommended?.map((val) => (
                <RestaurantCardComponent
                  key={val.restaurantId}
                  restaurantName={val.restaurantName}
                  restaurantId={val.restaurantId}
                  restaurantAddress={val.restaurantAddress}
                  isSaved={val.isSaved}
                  isOpen={val.isOpen}
                  tags={val.tags}
                  country={val.country}
                  images={val.images}
                />
              ))}
            </ProfileSection>

          </ColumnLayout>
        )}
      </Box>
    </Box>
  );
}

export default ProfilePage;
