import React from 'react';
import { IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';

function CompletedButtonComponent() {
  const { notify } = useCustomSnackbar();

  return (
    <IconButton
      aria-label="completed"
      color="primary"
      size="large"
      onClick={() => {
        notify('does nothing at the moment');
      }}
    >
      <DoneIcon fontSize="large" />
    </IconButton>
  );
}

export default CompletedButtonComponent;
