import React, { useState } from 'react';
import {
  Box, Button, Rating, TextField, Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { func, string } from 'prop-types';
import FormErrorTextComponent from '../typography/errorText';
import ColumnLayout from '../../layouts/column';
import reviewRestaurantService from '../../services/review/restaurant';
import { useCustomSnackbar } from '../../middlewares/hooks/snackbar.hook';
import { devlog } from '../../utils/console';

function FormInputLabel({ label, helperText }) {
  return (
    <Box display="flex" textAlign="left" flexDirection="column" width="100%">
      <Typography variant="h6" fontWeight="bold">{label}</Typography>
      {helperText.length >= 0 && (
        <Typography variant="caption">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

FormInputLabel.propTypes = {
  label: string.isRequired,
  helperText: string,
};

FormInputLabel.defaultProps = {
  helperText: '',
};

function ReviewFormComponent({ submitReviewHandler, restaurantId }) {
  const [ratingVal, setRatingVal] = useState(3);

  const { notify } = useCustomSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      description: '',
      rating: ratingVal,
    },
  });

  const onSubmit = async (data) => {
    const reviewRes = await reviewRestaurantService(restaurantId, data.rating, data.description);
    if (reviewRes.error) {
      devlog('something went wrong');
      return;
      // something went wrong.
    }
    notify(reviewRes?.data?.message);
    submitReviewHandler();
  };
  const descriptionField = register('description', {
    validate: {
      notNone: (v) => {
        const rating = getValues('rating');
        if (rating < 3) {
          return v.length > 0 || 'please let them know how they can do better';
        }
        return true;
      },
    },
  });
  register('rating', { required: true, min: 1 });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnLayout>
        <FormInputLabel label="rating" helperText="out of 5, what would you give?" />
        <Rating
          name="ratings"
          value={ratingVal ?? 0}
          onChange={(event, newValue) => {
            setValue('rating', newValue);
            setRatingVal(Number(newValue));
          }}
          size="large"
        />
        <FormInputLabel label="description" helperText="tell us your thoughts" />
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          placeholder="description"
          type="text"
          ref={descriptionField.ref}
          name={descriptionField.name}
          onChange={descriptionField.onChange}
          onBlur={descriptionField.onBlur}
        />
        {errors.description && (
          <FormErrorTextComponent
            text={errors?.description?.message || 'description this is required'}
          />
        )}
        <Box display="flex" justifyContent="right" width="100%">
          <Button variant="contained" type="submit">submit</Button>
        </Box>
      </ColumnLayout>
    </form>
  );
}

ReviewFormComponent.propTypes = {
  submitReviewHandler: func.isRequired,
  restaurantId: string.isRequired,
};

export default ReviewFormComponent;
