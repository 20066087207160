import {
  Button, Card, CardContent, Link, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { bool, number, string } from 'prop-types';
import ColumnLayout from '../../layouts/column';
import RowLayout from '../../layouts/rows';
import RatingStarBarComponent from './ratingStarBar';
import { PublicProfilePathBuilder, RestaurantPathBuilder } from '../../constants/routes.contants';

function ReviewCardComponent({
  name, reviewText, rating, isPersonal,
  restaurantName, restaurantId,
}) {
  const [showMore, setShowMore] = useState(false);
  if (reviewText?.length === 0) {
    return null;
  }
  return (
    <Card
      variant="outlined"
      style={{
        width: '100%',
        minWidth: '323px',
        borderColor: '#000000',
      }}
    >
      <CardContent style={{ padding: '5px 10px' }}>
        <ColumnLayout overflow="flex" alignItems="flex-start" justifyContent="flex-start">
          <Typography textAlign="left" fontWeight="bold">
            {isPersonal ? 'your thoughts...'
              : (
                <>
                  <Link href={PublicProfilePathBuilder(name)}>
                    {name}
                  </Link>
                  {' '}
                  says...
                </>
              )}
          </Typography>
          {restaurantName && restaurantId && (
            <Link href={RestaurantPathBuilder(restaurantId)}>
              {restaurantName}
            </Link>
          )}
          <RatingStarBarComponent numStar={rating} />
          <RowLayout overflow="flex">
            <Typography textAlign="left" fontSize="50px" fontWeight="bold" lineHeight="35px">&quot;</Typography>
            <Typography textAlign="left">
              {showMore ? reviewText : `${reviewText.substring(0, 85)}...`}
            </Typography>
          </RowLayout>
          {reviewText.length > 85 && (
            <Button onClick={() => setShowMore(!showMore)}>
              {showMore ? 'show less' : 'show more'}
            </Button>
          )}
        </ColumnLayout>
      </CardContent>
    </Card>
  );
}

ReviewCardComponent.propTypes = {
  name: string,
  reviewText: string,
  rating: number,
  isPersonal: bool,
  restaurantName: string,
  restaurantId: number,
};

ReviewCardComponent.defaultProps = {
  name: 'anonymous person',
  reviewText: '',
  rating: 3,
  isPersonal: false,
  restaurantName: '',
  restaurantId: 0,
};

export default ReviewCardComponent;
